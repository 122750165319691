// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

jQuery(function ($) {
  // ------------------------------------------- SWIPER
  // ------------------------------------------- SLIDE
  const swiper = new Swiper(".swiper__all", {
    slidesPerView: 1,
    // loop: true,
    // autoplay: false,
    // loopAdditionalSlides: 20,
    // freeMode: true,
    spaceBetween: 0,
    speed: 700,
    // autoplay: {
    //   delay: 3500,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  $(".swiper__all .swiper-slide").hover(
    function () {
      swiper.autoplay.stop();
    },
    function () {
      swiper.autoplay.start();
    }
  );

  const swiper2 = new Swiper(".swiper__realisations", {
    slidesPerView: "auto",
    loop: true,
    freeMode: true,
    spaceBetween: 5,
    speed: 700,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  $(".swiper__realisations .swiper-slide").hover(
    function () {
      swiper2.autoplay.stop();
    },
    function () {
      swiper2.autoplay.start();
    }
  );
});
