// Bouton réservation fixed

window.addEventListener("load", () => {
  let observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting === false) {
          reservationBtn.classList.add("btn--reservation--active");
        } else {
          reservationBtn.classList.remove("btn--reservation--active");
        }
      });
    },
    {
      // si = 0.5 alors plus de la moitié de l'élement
      // si = 1 alors au début de l'element
      // possibilité de faire des array
      // threshold définit l'element
      threshold: 0,
      //rootmargin definit autour de l'element
      //à moins de 350px...
      rootMargin: "0px 0px 0px 0px",
    }
  );
  const hero = document.querySelector("#home-hero .btn");
  const reservationBtn = document.querySelector(".btn--reservation");

  if (hero != null) {
    observer.observe(hero);
  }
});
