// window.addEventListener("load", () => {

jQuery(document).ready(function ($) {
  if ($("body").hasClass("page-101")) {
    const salle = document.querySelectorAll(".card");
    const salleWrapper = document.querySelector(".cards");

    salle.forEach((card) => {
      card.addEventListener("click", () => {
        salle.forEach((salle) => {
          if (salle !== card) {
            salle.style.opacity = "0";
          }
        });
        $(".next__btn").addClass("next__btn--active");
        $(".back__btn").addClass("back__btn--active");
        card.classList.add("card--active");
        card.style.opacity = "1";
        salleWrapper.classList.add("salle--active");
      });
    });

    document.querySelector(".back__btn").addEventListener("click", () => {
      salle.forEach((salle) => {
        salle.style.opacity = "1";
        salle.style.display = "grid";
        salle.classList.remove("card--active");
      });
      salleWrapper.classList.remove("salle--active");
      $(".next__btn").removeClass("next__btn--active");
      $(".back__btn").removeClass("back__btn--active");
    });

    // salle.forEach((card) => {
    //   document.querySelector(".next__btn").addEventListener("click", () => {
    //     if (card.classList.contains("card--active")) {
    //       card.style.opacity = "0";
    //       card.classList.remove("card--active");
    //     }
    //     if (card.classList.contains("card--active") === false) {
    //       card.classList.add("card--active");
    //       card.style.opacity = "1";
    //     }
    //   });
    // });

    $(".next__btn").click(function () {
      var $card = $(".card.card--active")
        .removeClass("card--active")
        .css({ opacity: 0, display: "none" })
        .next(".card");

      if ($card.length) {
        $card.addClass("card--active").css({ opacity: 1, display: "grid" });
      } else {
        $(".card:first")
          .addClass("card--active")
          .css({ opacity: 1, display: "grid" });
      }
    });

    // $(".card").each(function () {
    //   var $this = $(this);

    //   $(".next__btn").click(function () {
    //     $(".card").next().addClass("card--active");
    //     if ($this.hasClass("card--active")) {
    //       $this.css("opacity", 0);
    //       $this.removeClass("card--active");
    //     } else {
    //       $this.css("opacity", 1);
    //     }
    //   });
    // });
  }
});
